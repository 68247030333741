import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {

    constructor() {
    }

    getMessage(error: any): string {
        let code;
        let message;
        if (error.headers.get('content-type') && error.headers.get('content-type').includes('application/vnd.graal.systems.v1.error+json;charset=UTF-8')) {
            code = error.error.code;
            message = error.error.message;
        } else {
            code = 'UNKNOWN';
            message = 'Unknown error';
        }
        switch (code) {
            case 'UNKNOWN':
                return message;
            case 'UNAUTHORIZED':
                return message;
            case 'MISSING_PARAMETER':
                return message;
            case 'WRONG_TYPE_PARAMETER':
                return message;
            case 'NO_VALID_PAYMENT_METHOD':
                return message;
            case 'QUOTA_EXCEEDED':
                return message;
            case 'INVALID_DEFINITION':
                return message;
            case 'IDENTITY_NOT_FOUND':
                return message;
            case 'USER_NOT_FOUND':
                return message;
            case 'JOB_NOT_FOUND':
                return message;
            case 'PROJECT_NOT_FOUND':
                return message;
            case 'EXPERIMENT_NOT_FOUND':
                return message;
            case 'INVOICE_NOT_FOUND':
                return message;
            case 'PAYMENT_METHOD_NOT_FOUND':
                return message;
            case 'IMAGE_NOT_FOUND':
                return message;
            case 'IMAGE_PROTECTED_OR_NOT_FOUND':
                return message;
            case 'IMAGE_INVALID_CREDENTIALS':
                return message;
            case 'MISSING_OWNER':
                return message;
            case 'MANAGED_BUCKET_ON_BAREMETAL':
                return message;
            case 'PAYMENT_VALID_METHOD_NOT_FOUND':
                return message;
            case 'NO_VALID_PAYMENT_METHOD':
                return message;
            default:
                return 'An error occurred, please verify your parameters and retry';
        }
    }
}
