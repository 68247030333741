import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {
    CostService,
    Device,
    DeviceService,
    Infrastructure,
    InfrastructureService,
    Patch,
    Project,
    ProjectService,
    RunService,
    RunStats,
    RunWithJob
} from '../../../generated';
import {ThemeOption} from 'ngx-echarts';
import {EChartsOption} from 'echarts';
import {UserSettingsService} from '../../shared/services/user-settings.service';
import {AuthenticationService} from '../../auth/authentication.service';
import {InternalHistoryService} from '../../shared/services/internal-history.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ErrorService} from '../../shared/services/error.service';
import {SidebarService} from '../../shared/services/sidebar.service';


@Component({
    selector: 'app-project-details-page',
    templateUrl: './project-details.component.html',
    styleUrls: ['./project-details.component.less']
})
export class ProjectDetailsComponent implements OnInit {

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private costService: CostService,
        private runService: RunService,
        private projectService: ProjectService,
        private historyService: InternalHistoryService,
        private infrastructureService: InfrastructureService,
        private deviceService: DeviceService,
        private userSettingsService: UserSettingsService,
        private sidebarService: SidebarService,
        private errorService: ErrorService
    ) {
        this.project = {};
    }

    labelsForm: UntypedFormGroup;

    project: Project;
    runWithJobs: RunWithJob[];

    stats: RunStats;
    statsReady = false;
    activityReady = false;
    totalBudgetReady = false;
    budgetReady = false;
    latestRunsReady = false;

    controls: Map<string, UntypedFormControl> = new Map<string, UntypedFormControl>();

    infrastructures: Map<string, Infrastructure> = new Map();
    devices: Map<string, Device> = new Map();

    budget: EChartsOption = {
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [{
            type: 'time',
            boundaryGap: false,
            splitLine: {
                show: false
            },
            axisLabel: {
                rotate: 30
            }
        }],
        yAxis: {
            splitNumber: 3
        },
        series: [{
            name: 'L',
            type: 'line',
            smooth: false,
            data: [],
            lineStyle: {
                opacity: 0
            },
            stack: 'confidence-band',
            symbol: 'none'
        }, {
            name: 'U',
            type: 'line',
            smooth: false,
            data: [],
            lineStyle: {
                opacity: 0
            },
            areaStyle: {
                color: '#ccc'
            },
            stack: 'confidence-band',
            symbol: 'none'
        }, {
            type: 'line',
            smooth: false,
            data: [],
            symbolSize: 6,
            itemStyle: {
                color: '#333'
            },
            showSymbol: false
        }]
    };

    activity: EChartsOption = {
        legend: {
            top: '5%',
            left: 'center'
        },
        series: [
            {
                name: 'area',
                type: 'pie',
                radius: ['60%', '80%'],
                avoidLabelOverlap: false,
                color: [
                    '#db2100',
                    '#61B715',
                    '#e8ab31',
                    '#CCCCCC'
                ],
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '30',
                        fontFamily: 'Metropolis, Avenir Next, Helvetica Neue, Arial, sans-serif',
                    }
                },
                labelLine: {
                    show: false
                },
                data: []
            }
        ]
    };

    totalBudget: EChartsOption = {
        series: [
            {
                name: 'area',
                type: 'pie',
                radius: ['60%', '80%'],
                avoidLabelOverlap: false,
                color: [
                    '#0072a3'
                ],
                label: {
                    show: true,
                    fontSize: '40',
                    fontFamily: 'Metropolis, Avenir Next, Helvetica Neue, Arial, sans-serif',
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: false
                    }
                },
                labelLine: {
                    show: false
                },
                data: []
            }
        ]
    };

    gradient: EChartsOption = {
        visualMap: {
            top: 50,
            right: 10,
            pieces: this.theme().visualMap.pieces,
            textStyle: this.theme().textStyle
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: [{
            type: 'time',
            boundaryGap: false,
            splitLine: {
                show: false
            },
            axisLabel: {
                rotate: 30
            }
        }],
        yAxis: {},
        series: [{
            type: 'line',
            smooth: false,
            showSymbol: false
        }]
    };
    openDeleteModal = false;
    deleting = false;

    openEditLabels = false;
    editingLabels = false;

    errorMessage: string;

    createKVItem(): UntypedFormGroup {
        return this.formBuilder.group({
            key: [],
            value: []
        });
    }

    getLabel() {
        return (this.labelsForm.get('labels') as UntypedFormArray).controls;
    }

    addLabel(): void {
        const details = this.labelsForm.get('labels') as UntypedFormArray;
        details.push(this.createKVItem());
    }

    removeLabel(index): void {
        const details = this.labelsForm.get('labels') as UntypedFormArray;
        details.removeAt(index);
    }

    updateLabels(): void {
        const labels = this.labelsForm.get('labels').value
            .filter(obj => obj.key != null && obj.key !== '')
            .reduce(function (map, obj) {
                map[obj.key] = obj.value;
                return map;
            }, {});
        this.internalUpdate(this.project.labels, labels, 'labels');
    }

    theme(): ThemeOption {
        return this.userSettingsService.getTheme();
    }

    ngOnInit(): void {
        // Retrieve the prefetched project
        this.route.data.subscribe(
            (data) => {
                this.project = data.project;

                if (this.project.labels) {
                    const labels = Object.entries(this.project.labels).map(([key, value]) => {
                        return this.formBuilder.group({
                            key: [key],
                            value: [value]
                        });
                    });
                    labels.push(this.createKVItem());
                    this.labelsForm = this.formBuilder.group({
                            labels: this.formBuilder.array(labels)
                        }
                    );
                }

                this.historyService.addResourceHistoryForUser('project', this.project.id);

                this.updateEditForm();

                this.infrastructureService.findInfrastructures(this.authenticationService.getTenant())
                    .subscribe(r => {
                        this.infrastructures = new Map(r.map(i => [i.id, i]));
                    });
                this.deviceService.findDevices(this.authenticationService.getTenant())
                    .subscribe(r => {
                        this.devices = new Map(r.content.map(i => [i.id, i]));
                    });

                const now = new Date().getUTCMilliseconds();
                const toDate = new Date();
                toDate.setMilliseconds(now);
                const fromDate = new Date();
                fromDate.setSeconds(now / 1000 - 365 * 24 * 60 * 60);
                this.totalBudgetReady = false;
                this.budgetReady = false;
                this.costService.getCosts(this.authenticationService.getTenant(), fromDate.toISOString(), toDate.toISOString(), 'project', {project: data.project.id as string}).subscribe(e => {
                    this.totalBudget.series[0].data = [{value: e.total, name: Number(e.total).toFixed(2) + '€'}];
                    this.totalBudgetReady = true;

                    if (e.budget_lower_projection) {
                        this.budget.series[0].data = e.period.map((value, index) => [value, e.budget_lower_projection[index]]);
                    } // Lower
                    if (e.budget_upper_projection) {
                        this.budget.series[1].data = e.period.map((value, index) => [value, e.budget_upper_projection[index]]);
                    } // Upper
                    this.budget.series[2].data = e.period.map((value, index) => [value, e.cumulative[index]]);

                    this.budgetReady = true;
                });

                // this.budget.series[2].markLine = {
                //     animation: false,
                //     label: 'Now',
                //     data: [ { xAxis: 'Thu' } ]
                // };

                this.activityReady = false;
                this.projectService.getMetricsForProject(this.authenticationService.getTenant(), data.project.id as string, 'cpu', fromDate.toISOString(), toDate.toISOString()).subscribe(e => {
                    this.gradient.xAxis[0].data = e.map(t => t.at);
                    this.gradient.series[0].data = e.map(t => t.value);
                    this.activityReady = true;
                });

                this.statsReady = false;
                this.projectService.getStatsByProjectId(this.authenticationService.getTenant(), data.project.id as string)
                    .subscribe(r => {
                        this.activity.series[0].data = [
                            {value: r.failed, name: 'Failed'},
                            {value: r.completed, name: 'Completed'},
                            {value: r.running, name: 'Running'},
                            {value: r.unknown, name: 'Unknown'}
                        ];
                        this.stats = r;
                        this.statsReady = true;
                    });

                this.latestRunsReady = false;
                this.projectService.findLatestRunsByProjectId(this.authenticationService.getTenant(), data.project.id as string, 10)
                    .subscribe(r => {
                        this.runWithJobs = r;
                        this.latestRunsReady = true;
                    });

            }
        );
    }

    delete(): void {
        this.deleting = true;
        this.projectService.deleteProjectById(this.authenticationService.getTenant(), this.project.id)
            .subscribe(a => {
                this.openDeleteModal = false;
                this.deleting = false;
                this.sidebarService.forceRefreshProjects();
                this.router.navigate(['projects']);
            }, e => {
                this.errorMessage = this.errorService.getMessage(e);
                this.deleting = false;
            });
    }

    get(field: string) {
        const formControl = this.controls.get(field);
        return formControl;
    }

    private updateEditForm(): void {
        this.controls.set('name', new UntypedFormControl());
        this.get('name').setValue(this.project.name);
        this.controls.set('description', new UntypedFormControl());
        this.get('description').setValue(this.project.description);
    }


    update(old, field: string): void {
        if (this.controls.get(field) && this.controls.get(field).value) {
            const value = this.controls.get(field).value as any;

            this.internalUpdate(old, value, field);
        }
    }

    private internalUpdate(old, value, field: string): void {
        const patch: Patch = {
            op: old ? Patch.OpEnum.Replace : Patch.OpEnum.Add,
            value,
            path: '/' + field
        };
        this.projectService.updateProject(this.authenticationService.getTenant(), this.project.id as string, [patch])
            .subscribe(r => {
                this.openEditLabels = false;
                this.project = r;
                this.updateEditForm();
                this.sidebarService.forceRefreshProjects();
            });
    }

    onTimelineChange($event: any): void {
        if ($event.target.id) {
            const interval = $event.target.id;

            const now = new Date().getUTCMilliseconds();
            const toDate = new Date();
            toDate.setMilliseconds(now);
            const fromDate = new Date();
            fromDate.setSeconds(now / 1000 - 60 * 60);

            if ('timescale-1' === interval) {
                fromDate.setSeconds(fromDate.getSeconds() - 60 * 60);
            } else if ('timescale-2' === interval) {
                fromDate.setSeconds(fromDate.getSeconds() - 6 * 60 * 60);
            } else if ('timescale-3' === interval) {
                fromDate.setSeconds(fromDate.getSeconds() - 12 * 60 * 60);
            } else if ('timescale-4' === interval) {
                fromDate.setSeconds(fromDate.getSeconds() - 24 * 60 * 60);
            } else if ('timescale-5' === interval) {
                fromDate.setSeconds(fromDate.getSeconds() - 7 * 24 * 60 * 60);
            } else if ('timescale-6' === interval) {
                fromDate.setSeconds(fromDate.getSeconds() - 30 * 24 * 60 * 60);
            }

            this.projectService.getMetricsForProject(this.project.id, 'cpu', fromDate.toISOString(), toDate.toISOString()).subscribe(e => {
                this.gradient.xAxis[0].data = e.map(t => t.at);
                this.gradient.series[0].data = e.map(t => t.value);
                this.activityReady = true;
            });

        }
    }
}
