import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import '@cds/core/tag/register';
import {Activity, ActivityService, FollowService, Reaction, ResourceType, UserService} from '../../../../generated';
import {AuthenticationService} from '../../../auth/authentication.service';
import {UserSettingsService} from '../../services/user-settings.service';
import {DOCUMENT} from '@angular/common';
import {fromEvent} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import * as jsdiff from 'diff';

@Component({
    selector: 'app-timeline',
    styles: [`
        ::ng-deep .in-clr-stack-block .stack-block-label .stack-view-key {
            max-width: 0px;
        }
    `],
    templateUrl: './timeline-activity.component.html'
})
export class TimelineActivityComponent implements OnChanges, OnInit {

    @Input()
    resourceId: string;
    @Input()
    resourceType: ResourceType;

    @ViewChild('search_reactions', {static: false})
    elementRef: ElementRef;

    // usersLoading = false;

    openSearchModal = false;
    isSearching = false;
    search = '';

    activities: Array<Activity>;

    reactions = [
        {id: 'beaming-face', icon: 'fluent-emoji:beaming-face-with-smiling-eyes'},
        {id: 'anguished-face', icon: 'fluent-emoji:anguished-face'},
        {id: 'anxious-face', icon: 'fluent-emoji:anxious-face-with-sweat'},
        {id: 'astonished-face', icon: 'fluent-emoji:astonished-face'},
        {id: 'angry-face', icon: 'fluent-emoji:angry-face'},
        {id: 'broken-heart', icon: 'fluent-emoji:broken-heart'},
        {id: 'bottle', icon: 'fluent-emoji:bottle-with-popping-cork'},
        {id: 'call-me', icon: 'fluent-emoji:call-me-hand'},
        {id: 'bullseye', icon: 'fluent-emoji:bullseye'},
        {id: 'beer', icon: 'fluent-emoji:clinking-beer-mugs'},
        {id: 'confounded-face', icon: 'fluent-emoji:confounded-face'},
        {id: 'cloud-with-lightning', icon: 'fluent-emoji:cloud-with-lightning'},
        {id: 'clown-face', icon: 'fluent-emoji:clown-face'},
        {id: 'crossed-fingers', icon: 'fluent-emoji:crossed-fingers'},
        {id: 'drooling-face', icon: 'fluent-emoji:drooling-face'},
        {id: 'face-vomitinging', icon: 'fluent-emoji:face-vomiting'},
        {id: 'face-with-peeking-eye', icon: 'fluent-emoji:face-with-peeking-eye'},
        {id: 'face-with-tears-of-joy', icon: 'fluent-emoji:face-with-tears-of-joy'},
        {id: 'face-with-peeking-eye', icon: 'fluent-emoji:xxx'},
        {id: 'frowning-face', icon: 'fluent-emoji:frowning-face'},
        {id: 'ghost', icon: 'fluent-emoji:ghost'},
        {id: 'heart-suit', icon: 'fluent-emoji:heart-suit'},
        {id: 'ok-hand', icon: 'fluent-emoji:ok-hand'},
        {id: 'thumbs-up', icon: 'fluent-emoji:thumbs-up'},
        {id: 'thumbs-down', icon: 'fluent-emoji:thumbs-down'},
        {id: 'police-officer', icon: 'fluent-emoji:police-officer'},
        {id: 'man-gesturing-no', icon: 'fluent-emoji:man-gesturing-no'},
        {id: 'no-one-under-eighteen', icon: 'fluent-emoji:no-one-under-eighteen'}
    ];
    results = this.reactions;
    activity: Activity;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private authenticationService: AuthenticationService,
        private userSettingsService: UserSettingsService,
        private followService: FollowService,
        private userService: UserService,
        private activityService: ActivityService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    openModal(activity: Activity): void {
        this.activity = activity;
        this.changeDetectorRef.detectChanges();
        fromEvent(this.elementRef.nativeElement, 'keyup').pipe(
            // get value
            map((event: any) => {
                return event.target.value;
            })
            // if character length greater then 2
            // , filter(res => res.length > 1)
            // Time in milliseconds between key events
            // , debounceTime(1000)
            // If previous query is diffent from current
            , distinctUntilChanged()
            // subscription for response
        ).subscribe((text: string) => {
            this.isSearching = true;
            this.openSearchModal = true;

            if (text && text !== '') {
                this.results = this.reactions.filter((item) => {
                    return item.id.toLowerCase().includes(text.toLowerCase());
                });
            } else {
                this.results = this.reactions;
            }
            this.isSearching = false;
        });
        this.openSearchModal = true;
    }

    addReactionAndCloseModal(reaction: string): void {
        if (this.activity) {

            // Delete existing reactions
            // const userId = this.authenticationService.getInternalUser().id;
            // const subscriptions = this.activity.reaction_count
            //     .filter(r => r.users.map(u => u.id).indexOf(userId))
            //     .map(r =>
            //         this.activityService.activitiesActivityIdReactionsReactionIdDelete(this.authenticationService.getTenant(), this.activity.id, r.id)
            //     );
            // forkJoin(subscriptions)
            //     .subscribe(a => {
            //         this.activity.reaction_count
            //             .filter(r => r.users.map(u => u.id).indexOf(userId))
            //             .forEach(r => {
            //                 r.count -= 1;
            //                 r.users = r.users.filter(u => u.id !== userId);
            //             });
            //     });

            // Add reaction
            const reactionDto: Reaction = {
                id: reaction,
                count: 1
            };
            this.activityService.createReactionForActivity(this.authenticationService.getTenant(), this.activity.id, reactionDto)
                .subscribe(r => {
                    this.activity.reaction_count = r;
                });
        }
        this.openSearchModal = false;
    }

    diff(left, right) {
        const diff = jsdiff.diffJson(this.formatJson(JSON.stringify(left)), this.formatJson(JSON.stringify(right)));
        return diff;
    }

    private formatJson(text: string): string {
        return JSON.stringify(JSON.parse(text), null, '  ');
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.activities = [];
        this.ngOnInit();
    }

    ngOnInit(): void {
        if (this.resourceId && this.resourceType) {
            this.activityService.getActivitiesFromTimeline(this.authenticationService.getTenant(), this.resourceId, this.resourceType)
                .subscribe(r => {
                    this.activities = r;
                });
        }
    }

    getIcon(id: string): string {
        return this.reactions.find(r => {
            return r.id === id;
        }).icon;
    }

    // loadUsers(activity: Activity, reaction: Reaction): Observable<User[]> {
    //     if (!this.usersLoading) {
    //         this.usersLoading = true;
    //         return this.activityService.getReactionsFromActivity(this.authenticationService.getTenant(), activity.id)
    //             .pipe(
    //                 map(rs => {
    //                     return rs.filter(r => r.id === reaction.id).pop().users.map(u => u.id);
    //                 }),
    //                 map(rs => {
    //                     return forkJoin(rs.map(u => this.userService.findUserById(this.authenticationService.getTenant(), u)));
    //                 }),
    //                 tap(e => {
    //                     console.log(e);
    //                     this.usersLoading = true;
    //                 })
    //             );
    //     }
    //     return null;
    // }
}
