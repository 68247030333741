<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'spark'"><span class="iconify-inline" data-icon="cib:apache-spark"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'python'"><span class="iconify-inline" data-icon="bx:bxl-python"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'pytorch'"><span class="iconify-inline" data-icon="cib:pytorch"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'lowcode'"><span class="iconify-inline" data-icon="ic:outline-code-off"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'tensorflow'"><span class="iconify-inline" data-icon="cib:tensorflow"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'flink'"><span class="iconify-inline" data-icon="cib:apache-flink"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'dask'"><span class="iconify-inline" data-icon="simple-icons:dask"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'mxnet'"><span class="iconify-inline" data-icon="bx:bxl-python"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'sas'"><span class="iconify-inline" data-icon="file-icons:sas"
            style="font-size: {{size*20/16}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'knime'"><span class="iconify-inline" data-icon="file-icons:knime"
            style="font-size: {{size*20/16}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'dbt'"><span class="iconify-inline" data-icon="simple-icons:dbt"
            style="font-size: {{size*20/16}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'scikitlearn'"><span class="iconify-inline" data-icon="simple-icons:scikitlearn"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'bash'"><span class="iconify-inline" data-icon="codicon:terminal-bash"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'hadoop'"><span class="iconify-inline" data-icon="simple-icons:apachehadoop"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'mapreduce'"><span class="iconify-inline" data-icon="simple-icons:apachehadoop"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'sqoop'"><span class="iconify-inline" data-icon="simple-icons:apachehadoop"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'hive'"><span class="iconify-inline" data-icon="simple-icons:apachehive"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'pulsar'"><span class="iconify-inline" data-icon="simple-icons:apachepulsar"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'beam'"><span class="iconify-inline" data-icon="cib:apache"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'akka-data-pipeline'"><span class="iconify-inline" data-icon="file-icons:akka"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'spring-cloud-data-flow'"><span class="iconify-inline" data-icon="cib:spring"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'spring-batch'"><span class="iconify-inline" data-icon="cib:spring"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'vscode'"><span class="iconify-inline" data-icon="vscode-icons:file-type-vscode"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'sas-studio'"><span class="iconify-inline" data-icon="file-icons:sas"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'superset'"><span class="iconify-inline" data-icon="cib:apache"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'jupyter'"><span class="iconify-inline" data-icon="cib:jupyter"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'metabase'"><span class="iconify-inline" data-icon="simple-icons:metabase"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'trino'"><span class="iconify-inline" data-icon="simple-icons:trino"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'clickhouse'"><span class="iconify-inline" data-icon="simple-icons:clickhouse"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'druid'"><span class="iconify-inline" data-icon="simple-icons:apachedruid"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'starburst'"><img src="assets/starburst.png"
            style="height: {{size}}px;margin-top: -6px;" /> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'doris'"><span class="iconify-inline" data-icon="cib:apache"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'zeppelin'"><span class="iconify-inline" data-icon="cib:apache"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'livecoding'"><span class="iconify-inline" data-icon="ic:outline-code-off"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'mariadb'"><span class="iconify-inline" data-icon="logos:mariadb-icon"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'mysql'"><span class="iconify-inline" data-icon="logos:mysql"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'postgresql'"><span class="iconify-inline" data-icon="logos:postgresql"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'db2'"><span class="iconify-inline" data-icon="carbon:ibm-db2-alt"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'snowflake'"><span class="iconify-inline" data-icon="logos:snowflake-icon"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'synapse'"><span class="iconify-inline" data-icon="logos:microsoft-azure"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'oracle'"><span class="iconify-inline" data-icon="logos:oracle"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'database-migration'"><span class="iconify-inline"
            data-icon="eos-icons:database-migration" style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchCase="'docker'"><span class="iconify-inline" data-icon="simple-icons:docker"
            style="font-size: {{size}}px;"></span> {{word}}</ng-container>
    <ng-container *ngSwitchDefault><cds-icon shape="data-cluster"></cds-icon> {{word}}</ng-container>
    <!-- TODO: missing size="{{size}}" -->
</ng-container>