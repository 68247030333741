import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {User, UserService} from '../../../../generated';
import {AuthenticationService} from '../../../auth/authentication.service';

@Component({
    selector: 'app-user-fullname',
    templateUrl: './user-fullname.component.html'
})
export class UserFullnameComponent implements OnChanges, OnInit {

    @Input()
    userId: string;

    user: User;

    constructor(private authenticationService: AuthenticationService,
                private userService: UserService
    ) {
    }

    ngOnInit(): void {
        if (this.userId) {
            this.userService.findUserById(this.authenticationService.getTenant(), this.userId).subscribe(u => {
                this.user = u;
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ngOnInit();
    }
}
