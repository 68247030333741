import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-runtime-icon',
    templateUrl: './runtime-icon.component.html'
})
export class RuntimeIconComponent implements OnInit {

    @Input()
    type: string;

    @Input()
    size: number;

    @Input()
    displayTitle = false;

    word = '';

    constructor() {
    }

    ngOnInit(): void {
        if (this.displayTitle) {
            switch (this.type) {
                case 'bash':
                    this.word = 'Bash';
                    break;
                case 'pytorch':
                    this.word = 'PyTorch';
                    break;
                case 'dask':
                    this.word = 'Dask';
                    break;
                case 'python':
                    this.word = 'Python';
                    break;
                case 'flink':
                    this.word = 'Apache Flink';
                    break;
                case 'docker':
                    this.word = 'Docker';
                    break;
                case 'sas':
                    this.word = 'SAS';
                    break;
                case 'spark':
                    this.word = 'Apache Spark';
                    break;
                case 'hadoop':
                    this.word = 'Apache Hadoop';
                    break;
                case 'sqoop':
                    this.word = 'Apache Sqoop';
                    break;
                case 'spring-batch':
                    this.word = 'Spring Batch';
                    break;
                case 'tensorflow':
                    this.word = 'Tensorflow';
                    break;
                case 'xgboost':
                    this.word = 'XGBoost';
                    break;
                case 'mxnet':
                    this.word = 'MXNet';
                    break;
                case 'knime':
                    this.word = 'KNIME';
                    break;
                case 'dbt':
                    this.word = 'DBT';
                    break;
                case 'vscode':
                    this.word = 'Visual Studio Code';
                    break;
                case 'sas-studio':
                    this.word = 'SAS Studio';
                    break;
                case 'zeppelin':
                    this.word = 'Apache Zeppelin';
                    break;
                case 'jupyter':
                    this.word = 'Jupyter Lab';
                    break;
                case 'superset':
                    this.word = 'Apache Superset';
                    break;
                case 'metabase':
                    this.word = 'Metabase';
                    break;
                case 'lowcode':
                    this.word = 'Low-code';
                    break;
                case 'database-migration':
                    this.word = 'Database Migration Tools';
                    break;
                case 'trino':
                    this.word = 'Trino';
                    break;
                case 'druid':
                    this.word = 'Apache Druid';
                    break;
                case 'clickhouse':
                    this.word = 'Clickhouse';
                    break;
                case 'doris':
                    this.word = 'Apache Doris';
                    break;
                case 'starburst':
                    this.word = 'Starburst';
                    break;
                case 'livecoding':
                    this.word = 'Live Coding';
                    break;
                case 'mysql':
                    this.word = 'MySQL';
                    break;
                case 'mariadb':
                    this.word = 'MariaDB';
                    break;
                case 'postgresql':
                    this.word = 'PostgreSQL';
                    break;
                case 'db2':
                    this.word = 'DB2';
                    break;
                case 'snowflake':
                    this.word = 'Snowflake';
                    break;
                case 'synapse':
                    this.word = 'Azure Synapse';
                    break;
                case 'oracle':
                    this.word = 'Oracle';
                    break;
            }
        }
    }
}
